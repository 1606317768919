import { mapActions, mapGetters } from "vuex";

export default {
    name: "ingredients",
    props: {
    },
    data() {
        return {
            letterArray: [
                { id: null, title: "Всі" },
                { id: 1, title: "А" },
                { id: 2, title: "Б" },
                { id: 3, title: "В" },
                { id: 4, title: "Г" },
                { id: 5, title: "Д" },
                { id: 6, title: "Е" },
                { id: 7, title: "Є" },
                { id: 8, title: "Ж" },
                { id: 9, title: "З" },
                { id: 10, title: "И" },
                { id: 11, title: "І" },
                { id: 12, title: "Ї" },
                { id: 13, title: "Й" },
                { id: 14, title: "К" },
                { id: 15, title: "Л" },
                { id: 16, title: "М" },
                { id: 17, title: "Н" },
                { id: 18, title: "О" },
                { id: 19, title: "П" },
                { id: 20, title: "Р" },
                { id: 21, title: "С" },
                { id: 22, title: "Т" },
                { id: 23, title: "У" },
                { id: 24, title: "Ф" },
                { id: 25, title: "Х" },
                { id: 26, title: "Ц" },
                { id: 27, title: "Ч" },
                { id: 28, title: "Ш" },
                { id: 29, title: "Щ" },
                { id: 30, title: "Ю" },
                { id: 31, title: "Я" }
            ],
            selectedLetter: null,
        };
    },
    watch: {
        selectedLetter: {
          immediate: true,
          deep: true,
          handler(newVal){
              if(newVal !== null){
                  this.fetchIngredientsList({letter: this.letterArray.find(el => el.id === newVal).title})
              } else {
                  this.fetchIngredientsList({letter: null})
              }
          }
      }
    },
    components: {
    },
    computed: {
        ...mapGetters({
            ingredientList: 'ingredients/ingredientList',
            ingredientListLoading: 'ingredients/ingredientListLoading'
        })
    },
    mounted() {
    },
    methods: {
        ...mapActions({
            fetchIngredientsList: 'ingredients/GET_INGREDIENT_LIST',
        })
    },
};
